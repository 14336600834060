import React from "react"
import * as styles from '../styles/animationLoops.module.scss'
import { useEffect, useState } from 'react'
import toTarget from '../js/goTarget'
const AnimationLoops = () => {
    const [turnNextAnimation, setTurnNextAnimation] = useState(0)
    let timer = null;
    let tag = 0;
    function pointAnimation() {
        setTurnNextAnimation(!turnNextAnimation)
        setTimeout(() => {
            pointAnimation()
            tag = !tag
            setTurnNextAnimation(tag)
        }, 600);
    }
    useEffect(() => {
        pointAnimation()
        return () => {
            clearTimeout(timer)
            timer = null;
            
        };
    }, []);

    const anchorAnimation = (e) => {
        toTarget(e)
    }
    return (
        <div className={styles.turnNext} onClick={anchorAnimation}>
            <div className={turnNextAnimation ? `${styles.point} ${styles.pointAnimation}` : `${styles.point}`}></div>
        </div>
    )
}

export default AnimationLoops