import React from "react"
import * as styles from '../styles/home.module.scss'
import { useEffect, useState, useRef } from 'react'
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Email from '../components/email'
import AnimationLoops from '../components/animationLoops'
import bannerPartBg from '../images/home/bannerPartBg.png'
import target from '../images/home/target.png'
import tradex from '../images/home/tradex.png'
import tekkerMatch from '../images/home/tekkerMatch.png'
import ctio from '../images/home/ctio.png'
// import sight from '../images/home/sight.png'
// import example1 from '../images/home/example1.png'
// import example2 from '../images/home/example2.png'
// import NumOne from "../images/home/NumOne.png"
// import NumTwo from "../images/home/NumTwo.png"
// import NumThree from "../images/home/NumThree.png"
// import show1 from '../images/home/show1.png'
// import show2 from '../images/home/show2.png'
// import show3 from '../images/home/show3.png'
import services1 from '../images/home/services1.png'
import services2 from '../images/home/services2.png'
import services3 from '../images/home/services3.png'
import services4 from '../images/home/services4.png'
import show4 from '../images/home/show4.png'
import show5 from '../images/home/show5.png'
import corporate from '../images/home/Corporate.png'
import HR from '../images/home/HR.png'
import Sales from '../images/home/Sales.png'
import Technology from '../images/home/Technology.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import toTarget from '../js/goTarget'


import bootani from '../vedio/bootani2.mp4'

import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

const Home = ({ location }) => {
  // const showList = [
  //   {
  //     num: `01`,
  //     tit: 'Build a better business through recruiting the right talent.',
  //     des: 'Resilience will optimise your workforce for the new diverse and globalised labour market. Utilise emerging opportunities to hire remote talent to equip your business with the labour it needs to overcome challenges, grow and scale efficiently.',
  //     imgsrc: show1
  //   },
  //   {
  //     num: `02`,
  //     tit: 'Build a better business through recruiting the right talent.',
  //     des: 'Resilience will optimise your workforce for the new diverse and globalised labour market. Utilise emerging opportunities to hire remote talent to equip your business with the labour it needs to overcome challenges, grow and scale efficiently.',
  //     imgsrc: show2
  //   },
  //   {
  //     num: `03`,
  //     tit: 'Build a better business through recruiting the right talent.',
  //     des: 'Resilience will optimise your workforce for the new diverse and globalised labour market. Utilise emerging opportunities to hire remote talent to equip your business with the labour it needs to overcome challenges, grow and scale efficiently.',
  //     imgsrc: show3
  //   },
  // {
  //   num: `01`,
  //   tit: 'Build Resilience',
  //   des: 'Resilience will enable your business to leverage untapped resources towards new business opportunities. Our team possesses deep experience in tax, accounting, contracting, government funding, and tendering.',
  //   imgsrc: show1
  // },
  // {
  //   num: `02`,
  //   tit: 'Fuel Growth',
  //   des: 'Resilience will grow and scale your business’s marketing and sales strategies to increase sales and maximize ROI. Our team will help your B2B or B2C business attract more customers and leads and nurture them towards sales and reliable repeat business.',
  //   imgsrc: show2
  // },
  // {
  //   num: `03`,
  //   tit: 'Remote Collaboration',
  //   des: 'Resilience will optimize your workforce for the new diverse and globalized labor market. Utilize emerging opportunities to hire remote talent to equip your business with the labor it needs to overcome challenges, grow and scale efficiently.',
  //   imgsrc: show3
  // },
  // ]
  const heroList = [
    {
      num: 0,
      des: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et`,
      name: `Mai Mavinkurve`,
      posi1: `Chief Operating Officer  `,
      posi2: ``
    },
    {
      num: 1,
      des: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et`,
      name: `Mai Mavinkurve`,
      posi1: `Chief Operating Officer  `,
      posi2: ``
    },
    {
      num: 2,
      des: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et`,
      name: `Mai Mavinkurve`,
      posi1: `Chief Operating Officer  `,
      posi2: ``
    },
    {
      num: 3,
      des: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et`,
      name: `Mai Mavinkurve`,
      posi1: `Chief Operating Officer  `,
      posi2: ``
    },
  ]
  const textDom0 = useRef(null)
  const textDom1 = useRef(null)
  const textDom2 = useRef(null)
  const textDom3 = useRef(null)
  const serviceList = [
    {
      tit: `Technology`,
      imgs: Technology,
      link: '/services#Technology',
      detail: ["√ Technology Architecture", "√ Development Operations", "√ Security and Compliance"],
      dom: textDom0
    },
    {
      tit: `Human Resources`,
      imgs: HR,
      link: '/services#Human',
      detail: ["√ Resource planning", "√ Recruitment and hiring"],
      dom: textDom1
    },
    {
      tit: `Sales and Marketing`,
      imgs: Sales,
      link: '/services#Sales',
      detail: ["√ Sales & Marketing", "√ Lead generation", "√ Brand awareness"],
      dom: textDom2
    },

    {
      tit: `Corporate`,
      imgs: corporate,
      link: '/services#Corporate',
      detail: ["√ Bookkeeping", "√ Accounting", "√ Government funding"],
      dom: textDom3
    }
  ]
  const serviceArr = [
    {
      title: "Technology",
      text: "Building a successful product requires a strong foundation. You can leverage the expertise and talent of our in-house developers and engineers to provide the support for your product development.",
      text2:"Whether you're just getting started or well into the development process, our team is ready to help you take your project to the next level.",
      img: services1,
      link: "/services#Technology"
    },
    {
      title: "Human Resources",
      text: "We’re experts at building successful tech teams for our clients. If you’re looking for a team or an individual contributor our recruitment team will find the right candidate for your business.",
      text2:"In addition to our recruitment services, we also offer TekkerMatch, an online assessment tool that provides in-depth insights into a candidate's technical abilities and aptitude. With TekkerMatch, you can be confident that you're making informed hiring decisions.",
      img: services2,
      link: "/services#Human"
    },
    {
      title: "Sales & Marketing",
      text: "Resilience will laser-focus your brand positioning to maximize marketing investment and increase ROI.",
      img: services3,
      link: "/services#Sales"
    },
    {
      title: "Corporate",
      text: "Provides ongoing corporate support that enables businesses to streamline their use of resources.",
      img: services4,
      link: "/services#Corporate"
    },
  ]
  const leftLinkNameList = ['CLIENTS', 'SERVICES', 'CONTENT', 'ADVANTAGE', 'WELCOME']
  const linkList = ['#clients', '#service', '#turnwhat', '#turnwhy', '#banner']
  const [linkNow, setLinkNow] = useState(4);
  const [currentIndex, setCurrentIndex] = useState(0)
  const [clientsNum, setClientsNum] = useState(false)
  const [servicesImg, setServicesImg] = useState(false)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)
  const servicesElement = useRef(null)
  const [servicesBg, setServicesBg] = useState(false)
  const [phoneIdx, setPhoneIdx] = useState(0)
  const [phoneImgPosition, setPhoneImgPosition] = useState(false)

  useEffect(() => {
    if (document.body.clientWidth < 1024) {
      setClientsNum(true)
    } else {
      setClientsNum(false)
    }
  })
  // function changeBg(e) {
  //   let index = parseInt(e.target.dataset.index)
  //   // console.log(index);
  //   setCurrentIndex(index)
  // }
  const [currentImg, setCurrentImg] = useState(0)
  const [move, setMove] = useState(1)
  const screenHeight = typeof window !== "undefined" ? window.screen.height : ""
  let handlescroll = (e) => {
    let scrollTop = (e.srcElement ? e.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (e.srcElement ? e.srcElement.body.scrollTop : 0);
    let top = document.getElementById('clients') && document.getElementById('clients').getBoundingClientRect().top;

    // if (top < 600) {
    //   setLinkNow(0)
    // } else if (top < 1561) {
    //   setLinkNow(1)
    // } else if (top < 3370) {
    //   setLinkNow(2)
    // } else if (top < 4570) {
    //   setLinkNow(3)
    // } else if (top < 4900) {
    //   setLinkNow(4)
    // }

    // if (top > 2760 || top < 1688) {

    if (top > 3373 || top < 2278) {
      setMove(1)
      if (top > 2778) {
        setCurrentImg(0)
      } else {
        setCurrentImg(2)
      }
    } else {
      setMove(0)
      // if (top > 2559) {
      if (top > 2778) {
        setCurrentImg(0)
        // } else if (top < 2034) {
      } else if (top < 2300) {
        setCurrentImg(2)
      } else {
        setCurrentImg(1)
      }
    }


    if (scrollTop >= servicesElement.current.offsetTop) {
      setServicesBg(true)
    } else {
      setServicesBg(false)
    }

    //phone login

    let domTop0 = textDom0.current?.getBoundingClientRect().top + 92
    let domTop1 = textDom1.current?.getBoundingClientRect().top + 92
    let domTop2 = textDom2.current?.getBoundingClientRect().top + 92
    let domTop3 = textDom3.current?.getBoundingClientRect().top + 92
    if (domTop0 >= (screenHeight / 2) - 92) {
      setCurrentIndex(0)
      setPhoneIdx(0)
    } else if (domTop1 >= (screenHeight / 2) - 92) {
      setCurrentIndex(1)
      setPhoneIdx(1)
    } else if (domTop2 >= (screenHeight / 2) - 92) {
      setCurrentIndex(2)
      setPhoneIdx(2)
    } else if (domTop3 >= (screenHeight / 2) - 92) {
      setCurrentIndex(3)
      setPhoneIdx(3)
    }

    if (scrollTop >= 5828) {
      setPhoneImgPosition(true)
    } else if (scrollTop < 5828) {
      setPhoneImgPosition(false)
    }
  }

  const [openBoot, setOpenBoot] = useState(false)
  const dom = useRef(null)
  function bootanistatus() {
    let user = JSON.parse(sessionStorage.getItem("key"))
    if (!user) {
      setOpenBoot(true)
    } else {
      setOpenBoot(false)
    }
    sessionStorage.setItem("key", "123")
  }
  useEffect(() => {
    window.addEventListener('scroll', handlescroll);
    bootanistatus()
    return () => {
      window.removeEventListener('scroll', handlescroll);
    };
  }, []);


  // const anchorAnimation = (e) => {
  //   toTarget(e)
  // }
  const turnStatus = () => {
    setOpenBoot(false)
  }
  const mouseMove = (e, idx) => {
    setServicesImg(true)
    setCurrentIndex(idx)
    setTop(e.nativeEvent.offsetY - 260)
    setLeft(e.nativeEvent.offsetX - 524)
  }
  return (

    <>
      {
        openBoot ?

          <video onEnded={turnStatus} src={bootani} muted="muted" playsInline autoPlay="autoPlay" style={{ width: "100vw", height: "100vh" }} className={styles.bootact}></video>
          :
          <Layout >
            <Email />

            <section className={styles.home}>
              {/* <div style={{display:"none"}} className={styles.leftLink}>
                <ul>
                  {
                    linkList.map((item, index) => (
                      <li key={item}>
                        <Link to={item} onClick={anchorAnimation}>
                          <div className={styles.point} onClick={() => { setLinkNow(index) }} style={linkNow === index ? { background: "#111111" } : {}}></div>
                        </Link>
                      </li>
                    ))}

                  <li>
                    <span>
                      {
                        leftLinkNameList.map((item, index) => (
                          <span style={linkNow === index ? {} : { display: 'none' }}>{item}</span>
                        ))
                      }
                    </span>
                  </li>
                </ul>
              </div> */}
              <div className={styles.banner} id='banner'>
                <img src={bannerPartBg} alt="" />
                <div className={styles.contain}>
                  <div className={styles.top}>
                    <div className={styles.name} >
                      <div>
                        <Fade bottom cascade big duration={800}>
                          {/* <h4>Optimizing businesses</h4> */}
                          <h4>Team and Tech</h4>
                        </Fade>
                      </div>
                      <div>
                        <Fade bottom cascade big duration={800} delay={100}>
                          <h4>Together</h4>
                        </Fade>
                      </div>
                      {/* <div>
                        <Fade bottom cascade big duration={800} delay={200}>
                          <h4>new world</h4>
                        </Fade>
                      </div>
                      <div>
                        <Slide duration={800} left cascade delay={400}>
                          <i className={styles.ta}></i>
                        </Slide>
                      </div> */}

                      <div className={styles.phone_title}>
                        <Fade bottom cascade big duration={800}>
                          <h3>Optimizing businesses for success in today's new world</h3>
                        </Fade>
                      </div>
                      <Fade bottom duration={1600} delay={600} style={{ height: "66px" }}>
                        <p>We help clients establish self-sufficient tech teams and launch successful products. We do
                          this by recruiting both local and international talent and utilizing our own remote in-house
                          developers and engineers as a foundation for the project.</p>
                      </Fade>
                      <Fade>
                        <img src={target} alt="" />
                      </Fade>
                      <div>
                        <Link to="/services">
                          <Fade bottom delay={1200}>
                            <button>
                              Explore our services
                            </button>
                          </Fade>
                        </Link>
                      </div>
                    </div>
                    <Fade>
                      <img className={styles.target_img} src={target} alt="" />
                    </Fade>
                  </div>
                  <div className={styles.bottom}>
                    <ul>
                      <Slide top duration={1000} delay={800}>
                        <li>
                          <img src={tradex} alt="" />
                        </li>
                      </Slide>
                      <Slide top duration={1000} delay={900}>
                        <li>
                          <img src={tekkerMatch} alt="" />
                        </li>
                      </Slide>
                      <Slide top duration={1000} delay={1000}>
                        <li>
                          <img src={ctio} alt="" />
                        </li>
                      </Slide>
                      {/* <Slide top duration={1000} delay={1100}>
                        <li>
                          <img src={tradex} alt="" />
                        </li>
                      </Slide> */}
                    </ul>
                  </div>

                </div>
                <Link to="#turnwhy">
                  <AnimationLoops />
                </Link>

              </div>
              <div style={{ background: "#F1F1F1" }}>
                {/* <div className={styles.turnwhy} id='turnwhy'>
                  <div className={styles.why}>
                    <div style={{width:"876px"}}>
                    <div className={styles.bigTit}>
                      <Fade bottom delay={300}>
                        <h2>One Step at a Time</h2>
                      </Fade>
                    </div>
                    <p style={{ marginBottom: "20px" }}>Resilience Corporate Services is a modern, high-performance management consultancy built from the ground up to help businesses adapt their practices and achieve their goals in this ever-changing business landscape. We provide a full suite of tailor-made services designed to drive growth in your business.</p>
                    <p>Our expertise ranges from software development, recruitment, and sales to accounting and government funding. The broad range of expertise is often combined to provide tailored solutions for clientele.</p>
                    </div>
                    
                    <div className={styles.contain}>
                      <div style={{marginLeft:"524px"}} className={styles.example}>
                        <div className={styles.left}>
                          <img src={NumOne} alt="" />
                        </div>
                        <div className={styles.detail}>
                          <h3>Understanding</h3>

                          <p>We consult with you to understand the needs of your business and determine how we can provide the best long-term solution for you.</p>
                        </div>
                      </div>
                      <div className={styles.example} style={{marginLeft:"73px"},{ marginBottom: '100px' }} >
                      <div className={styles.left}>
                          <img src={NumTwo} alt="" />
                        </div>
                        <div className={styles.detail}>
                          <h3>Implementation</h3>
                          <p>Action is the next step. Our management team will create a timeline for the project and coordinate with you and our internal teams to ensure we are on track and milestones are met.</p>
                        </div>
                        
                      </div>
                      <div className={styles.example} style={{marginLeft:"454px"}}>
                        <div className={styles.left}>
                          <img src={NumThree} alt="" />
                        </div>
                        <div className={styles.detail}>
                          <h3>Realisation</h3>

                          <p>Upon completion of the project, we will review the objectives with you and ensure you are satisfied with the project's outcome.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

              </div>
              <div className={styles.turnwhat} id='turnwhat'>
                {/* <div className={styles.bigTit}>
                  <Fade bottom delay={300}>
                    <h3> What can I do for you</h3>
                  </Fade>
                </div> */}
                {/* <div className={styles.show}  >
                  {
                    showList.map((item, index) => (
                      <div className={styles.left} key={item.num} >
                        <span>{item.num}</span>
                        <h4>{item.tit}</h4>
                        <p>{item.des}</p>
                        <img src={showList[index].imgsrc} alt="" />
                      </div>
                    ))
                  }
                  <div
                    className={move ? `${styles.img_nomove}` : `${styles.img_move}`}
                    style={{ background: `url(${showList[currentImg].imgsrc}) no-repeat` }}>

                  </div>
                  <div
                    className={styles.img_bottom}
                    style={
                      move ?
                        { display: "initial", background: `url(${showList[currentImg].imgsrc}) no-repeat` }
                        : { display: "none", background: `url(${showList[currentImg].imgsrc}) no-repeat` }} >

                  </div>
                </div> */}
                <ul>
                  <li>
                    <div>
                      <h3>How it works</h3>
                      <p>RCS(Resilience Corporate Services) was built from the ground up to help businesses adapt their practices and achieve their goals in this ever-changing business landscape. We provide tailored services designed to drive growth in your business.</p>
                      <p>Our team of experts are ready to assist you with technical recruitment (using our exclusive assessment tool TekkerMarch), software development, machine learning, datatrust technology, and government funding.</p>
                    </div>
                    <div className={styles.img}><img src={show4} alt="" /></div>
                  </li>
                  <li>
                    <div>
                      <h3>Technical Recruitment</h3>
                      <p>Build better teams and get the right talent through the door.</p>
                      <p>Finding the right fit to add to your team isn’t as simple as making a job posting, reading resumes, and conducting interviews. There are a number of factors to consider, like experience, chemistry, proof of skills, and how they will adapt to your workforce.</p>
                      <p>Let us do the heavy lifting and vet the ideal candidate for you. We will create custom online examinations tailored to the position(s) you are looking to fill. </p>
                    </div>
                    <div className={styles.img}><img src={show5} alt="" /></div>
                  </li>
                </ul>
              </div>

              <div
                ref={servicesElement}
                className={styles.service}
                id='service'>
                <div className={styles.contain}>
                  <div>
                    <h3>Services</h3>
                    <p>We provide a suite of services to cater to your business needs and new<br /> practices to get the most out of today's marketplace</p>
                  </div>
                  <ul>
                    {
                      serviceArr.map((item, index) => {
                        return (
                          <li key={index}>
                            <img className="bgImg" src={item.img} alt="" />
                            <h3>{item.title}</h3>
                            <p className={styles.firstP}>{item.text}</p>
                            <p className={styles.lastP}>{item.text2}</p>
                            <Link to={item.link}>
                              <div>View More</div>
                            </Link>
                          </li>
                        )
                      })
                    }
                  </ul>
                  {/* <ul>
                    {
                      serviceList.map((item, index) => {
                        return (

                          <li key={index}>
                            <Link to={item.link} data-index={index}>
                              <span style={clientsNum ? { opacity: phoneIdx === index ? "1" : "0.1" } : {}}>0{index + 1}</span>
                              <div
                                className={styles.text}
                                ref={item.dom}
                                onMouseMove={(e) => mouseMove(e, index)}
                                onMouseOut={() => setServicesImg(false)}
                                style={clientsNum ? { opacity: phoneIdx === index ? "1" : "0.1" } : {}}
                              >{item.tit}
                              </div>
                              <ul>
                                {
                                  item.detail.map((x, i) => {
                                    return <li style={clientsNum ? { opacity: phoneIdx === index ? "1" : "0.1" } : {}} key={i}>{x}</li>
                                  })
                                }
                              </ul>
                              <Fade>
                                <div id={`serviceImg${index}`} className={styles.serImg} style={{
                                  top: `${top}px`, left: `${left}px`,
                                  display: `${servicesImg && currentIndex === index ? "block" : "none"}`
                                }}>

                                  <img style={{ objectFit: "cover" }} src={item.imgs} alt="" />
                                  
                                </div>
                              </Fade>

                            </Link>
                          </li>
                        )
                      })
                    }
                  </ul> */}
                  {/* <img
                    style={{ display: servicesBg && clientsNum ? "block" : "none" }}
                    src={serviceList[currentIndex].imgs}
                    className={`${styles.phoneImg} ${phoneImgPosition ? styles.phoneImg_ab : styles.phoneImg_fixed}`}
                    alt=""
                  /> */}
                </div>
              </div>
              <div className={styles.clients} id='clients' style={{ height: "0", padding: "0" }}>
                {/* <Fade bottom delay={300}>
                  <h2>Our Clients</h2>
                </Fade>
                <div className={styles.shufflings}>
                  <ul className={styles.hero}>
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={clientsNum ? 1 : 2}
                      loop={true}
                      mousewheel={true}
                      autoplay={true}
                      observer={true}
                      modules={[Pagination]}
                      pagination={clientsNum ? false : { clickable: true }}
                      onSlideChange={clientsNum ? () => { } : () => {
                        let father = document.getElementsByClassName('swiper-pagination')[0]
                        let index = '';
                        let active = document.getElementsByClassName('swiper-pagination-bullet-active')[0];
                        for (let i = 0; i < father?.children.length; i++) {
                          // let num = father.children[i].getAttribute('class').length;
                          document.getElementsByClassName('swiper-pagination')[0].children[i].innerHTML = '';
                          if (active.getAttribute('class') === father.children[i].getAttribute('class')) {
                            index = i;
                          }
                        }
                        let init = document.getElementsByClassName('swiper-pagination')[0];
                        init.children[index] ? init.children[index].innerHTML = `<span class="swiperBtn_outer"></span>` : init.innerHTML = '';
                      }}
                      onInit={clientsNum ? () => { } : () => {
                        let init = document.getElementsByClassName('swiper-pagination')[0]?.children[0];
                        init ? init.innerHTML = `<span class="swiperBtn_outer"></span>` : init.innerHTML = '';
                      }}
                    >
                      {
                        heroList.map((item, index) => (
                          <SwiperSlide key={item.num}>
                            <li>
                              <span className={styles.back}></span>
                              <p className={styles.pot}>"</p>
                              <p className={styles.top}>
                                {item.des}
                              </p>
                              <div className={styles.author}>
                                <div className={styles.des}>
                                  <p className={styles.names}>{item.name}</p>
                                  <p className={styles.posit}>{item.posi1}<br></br>{item.posi2}</p>
                                </div>
                                <div className={styles.iimg}>
                                  <img src={example1} alt="" />
                                </div>
                              </div>
                            </li>
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                  </ul>
                </div> */}
              </div>
            </section>
          </Layout>
      }
    </>



  )
}

export default Home