// extracted by mini-css-extract-plugin
export var toTop = "home-module--toTop--K-Enp";
export var home = "home-module--home--FKT+1";
export var point = "home-module--point--u3bZN";
export var leftLink = "home-module--leftLink--c26hJ";
export var banner = "home-module--banner--7IWwu";
export var contain = "home-module--contain--wxhf8";
export var top = "home-module--top--jg0-3";
export var name = "home-module--name--nPhG4";
export var phone_title = "home-module--phone_title--N9gjv";
export var ta = "home-module--ta---Wfkz";
export var target_img = "home-module--target_img--TO6yv";
export var bottom = "home-module--bottom--O9dXW";
export var turnwhy = "home-module--turnwhy--o2g3F";
export var why = "home-module--why--r9cfJ";
export var bigTit = "home-module--bigTit--ljtlq";
export var example = "home-module--example--mFW4D";
export var left = "home-module--left--phUs8";
export var detail = "home-module--detail--cMd1R";
export var turnwhat = "home-module--turnwhat--Fo0+G";
export var show = "home-module--show--qRKNm";
export var img_nomove = "home-module--img_nomove--GWDL2";
export var img_move = "home-module--img_move--Xl8r5";
export var img_bottom = "home-module--img_bottom--5HFbT";
export var img = "home-module--img--hbNjy";
export var service = "home-module--service--32Ny1";
export var clients = "home-module--clients--2Oeek";
export var shufflings = "home-module--shufflings--jLGCe";
export var hero = "home-module--hero--K4NTb";
export var back = "home-module--back--4LXMS";
export var pot = "home-module--pot--gr5HP";
export var author = "home-module--author--9jkma";
export var des = "home-module--des--ICR1V";
export var names = "home-module--names--3A24n";
export var posit = "home-module--posit--JE+K+";
export var iimg = "home-module--iimg--eQWxa";
export var bootact = "home-module--bootact--dEAP8";
export var text = "home-module--text--C+iEY";
export var phoneImg = "home-module--phoneImg--y4qIf";
export var phoneImg_fixed = "home-module--phoneImg_fixed--QDjJb";
export var phoneImg_ab = "home-module--phoneImg_ab--ybLVy";